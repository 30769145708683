import { render, staticRenderFns } from "./DashboardView.vue?vue&type=template&id=ea2216a4&scoped=true&"
import script from "./DashboardView.vue?vue&type=script&lang=js&"
export * from "./DashboardView.vue?vue&type=script&lang=js&"
import style0 from "./DashboardView.vue?vue&type=style&index=0&lang=css&"
import style1 from "./DashboardView.vue?vue&type=style&index=1&id=ea2216a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea2216a4",
  null
  
)

export default component.exports