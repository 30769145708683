<script>
import axios from 'axios';
import { mapState } from "vuex"
import cryptoRandomString from 'crypto-random-string';

export default {
    /*
    data(){
        return {
            keys:{
                publicKey: "BNc5fgO5KiglWjC3bc1hrnw2sPfuE4N1WXXxrxgBFZ2xwNTlIzW-pxKZIKIMIWrCtafbWh_BSmvDae36UWokPIA"
            }
        }
    },
    */
    computed: {
        ...mapState(["webPushKey","token"])
    },
    mounted(){
        this.serviceWorker();
    },
    methods:{
        async serviceWorker(){
            const register = await navigator.serviceWorker.register('/worker.js',{
                scope: '/notifications/'
            });

            const subscription = await register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: this.webPushKey
            });

            if ( this.$cookies.get('notification_cookie') === null ) {
              this.$cookies.set('notification_cookie',cryptoRandomString({length: 10}),'1y')
            }

            let cookie = this.$cookies.get('notification_cookie');

            const request = axios.create({
                baseURL: '/'
            });
            request
                .post(`/notifications/subscription?token=${this.token}&cookie=${cookie}`,subscription)
                .then( () => {
                    console.log("Exito Web Push")
                })
                .catch( error => {
                    console.log(error);
                })
        }
    }
}
</script>